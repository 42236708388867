.Table {
  width: 100%;
  border-spacing: 0px;
}
.TableWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.Headers {
  text-align: center;
  font-weight: 400;
  color: var(--color-gray-70);
  font-size: 14px;
  cursor: pointer;
  text-wrap: nowrap;
}

.Cells {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  width: 300px;
  padding: 20px 5px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* border-style: none solid solid none;
  border: solid 1px #000; */
}

/* tr:first-child .Cells:first-child {
  border-top-left-radius: 10px;
}

tr:last-child .Cells:first-child {
  border-bottom-left-radius: 10px;
}

/* Celle nell'ultima colonna */
/* tr:first-child .Cells:last-child {
  border-top-right-radius: 10px;
}

tr:last-child .Cells:last-child {
  border-bottom-right-radius: 10px;
} */
.rowsCell:hover {
  background-color: var(--color-gray-5);
  overflow: hidden;
}

.rowsCell:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.paginationWrapper {
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
}

.paginationContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.Noresultmessage {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
}
