body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}
:root {
  --color-white: #ffffff;
  --color-true-black: #000;
  --color-black: #17171a;
  --color-dark-blue: #1d0e76;
  --color-primary: #533cdd;
  --color-primary-90: #533cdd;
  --color-bg-blue: rgb(204, 204, 255);
  --color-primary-50: #bfb2ff;
  --color-primary-25: #f3f1ff;
  --color-success: #1d9745;
  --color-success-90: #01c76f;
  --color-success-25: #daf8eb;
  --color-success-light: #c6fed0;
  --color-green: #00c66e;
  --color-dark-green: #00a45b;
  --color-light-green: #2ae27b;
  --color-stroke: #eef1f4;
  --color-additional-1: #1f1f39;
  --color-gray-95: #181c20;
  --color-gray-90: #0e1114;
  --color-gray-80: #353c43;
  --color-gray-70: #4a545c;
  --color-gray-60: #616c76;
  --color-gray-50: #677582;
  --color-gray-40: #8da1b2;
  --color-gray-30: #8fa0af;
  --color-gray-25: #f8f9fa;
  --color-gray-20: #cdd5de;
  --color-gray-10: #d9e1eb;
  --color-gray-5: #f8f9fa;
  --color-error: #ed1347;
  --color-error-40: #ed6f8e;
  --color-error-25: #ffe6ec;
  --color-warning-50: #fffaeb;
  --color-warning-100: #fef0c7;
  --color-warning-darker: #ffc412;
}
