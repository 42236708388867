.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 8px 10px;
  border-radius: 12px;
  text-decoration: none;
  font-weight: 600;
  background-color: var(--color-primary);
  color: var(--color-white);
  cursor: pointer;
}

.Button:hover {
  opacity: 0.8;
}
