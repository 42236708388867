.paginationWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0px;
}

.paginationMiddleContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: auto;
  gap: 6px;
}

/* .paginationWrapper span {
  text-wrap: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: var(--color-gray-90);
} */

.paginationWrapper button,
.Dots {
  border: 1px solid var(--color-gray-10);
  background-color: var(--color-white);
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--color-gray-90);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.paginationMiddleContent button.active {
  background-color: var(--color-primary-25);
  color: #3f51b5;
  border: 1px solid var(--color-primary-50);
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

/* .paginationWrapper button:disabled {
  color: rgba(0, 0, 0, 0.26);
  cursor: not-allowed;
} */

/* .paginationWrapper select {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  padding: 8px 12px;
  border-radius: 6px;
  color: var(--color-gray-90);
  border: 1px solid var(--color-gray-10);
}

.PageSelection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.PageSelection p {
  margin: 0;
  text-wrap: nowrap;
}

@media screen and (max-width: 900px) {
  .paginationWrapper {
    flex-direction: column;
    gap: 10px;
  }
} */

@media screen and (max-width: 400px) {
  .paginationMiddleContent {
    justify-content: start;
  }
}
