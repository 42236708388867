.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  padding: 40px;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;
  overflow: hidden;
  gap: 20px;
}
.TitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.TitleWrapper > h1 {
  margin: 0;
}

.TableWrapper {
  width: 100%;
  overflow: auto;
}

.FirstColumn {
  text-align: left;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Status > select {
  padding: 10px;
  border-radius: 10px;
  margin: 0;
  border: none;
}

.Done {
  background-color: var(--color-success-light);
  border: 1px solid var(--color-success);
  color: var(--color-success);
}
.Progress {
  background-color: var(--color-warning-50);
  border: 1px solid var(--color-warning-darker);
  color: var(--color-warning-darker);
}
.NA {
  background-color: var(--color-error-25);
  border: 1px solid var(--color-error-40);
  color: var(--color-error-40);
}

@media screen and (max-width: 700px) {
  .App {
    padding: 40px 20px;
  }
  .FirstColumn {
    width: 100px;
  }
}
