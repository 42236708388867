.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 400px;
}

.FormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 300px;
}

.Message {
  text-decoration: underline;
  color: var(--color-primary);
}

.FormTitle > h1 {
  margin: 0;
}

.FormTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
}
