.Label {
  font-size: 12px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
  color: var(--color-gray-90);
}
.InputWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

.Required {
  font-size: 12px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
  color: var(--color-error);
}
/* .CheckboxWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  gap: 5px;
}
.CheckboxWrapper:hover {
  opacity: 0.8;
}

.CheckboxWrapper label {
  font-size: 15px;
  color: var(--color-gray-50);
  cursor: pointer;
}

.Checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 7px;
  background-color: white;
  transition: background-color 0.2s, border-color 0.2s;

  &:checked {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
}

.Checkbox:checked::before {
  content: url("../../images/check.svg");
  display: block;
  text-align: center;
  color: white;
  font-size: 16px;
} */

.Input {
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border-radius: 12px;
  border: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-stroke);
}

.Error {
  outline: 1px solid var(--color-error);
}

.Input::placeholder {
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: var(--color-gray-40);
}

.Input[type="text"] {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: var(--color-gray-90);
}
.Input:focus {
  outline: none;
}

.MultiselectWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.CheckboxModal {
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--color-stroke);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 10px;
  padding: 15px;
  width: fit-content;
  z-index: 1000;
}
